import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginTop: theme.spacing(13),
    paddingLeft: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    marginTop: 0,
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    paddingTop: theme.spacing(2.5)
  };
  return styles;
});
export { StyledBox, StyledContentWrapper };