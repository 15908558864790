export var getDepartmentAddress = function getDepartmentAddress(department) {
  if (!department.postalAddress) {
    return '';
  }

  var _department$postalAdd = department.postalAddress,
      addressLine = _department$postalAdd.addressLine,
      townName = _department$postalAdd.townName,
      streetName = _department$postalAdd.streetName,
      buildingNumber = _department$postalAdd.buildingNumber;
  return "".concat(townName, ", ").concat(addressLine || "".concat(streetName || '', " ").concat(buildingNumber || ''));
};