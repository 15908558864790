import { styled as t, Box as o } from "@mui/material";
var r = t(o)(function (t) {
  var o,
      r = t.theme,
      a = t.$showBottomShadow;
  return (o = {})["&:after"] = {
    backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
    bottom: -1,
    content: "''",
    height: 100,
    left: "50%",
    opacity: a ? 1 : 0,
    pointerEvents: "none",
    position: "fixed",
    transform: "translate3d(-50%,0,0)",
    transition: r.transitions.create(["opacity"]),
    width: "100%",
    zIndex: 1
  }, o;
});
export { r as StyledBox };