import { Environment, EnvironmentMode } from '@enums';
import { i18n } from '@localization';
/**
 * Constants util.
 *
 * @author Mikhail Siutsou
 * @const constantsUtil
 * @category Utils
 */

var ConstantsUtil = {
  CURRENT_MODE: process.env.mode,
  CURRENT_SERVER: process.env.server,
  actions: {
    ASYNC_FAILED: 'ASYNC_FAILED',
    ASYNC_SUCCESS: 'ASYNC_SUCCESS'
  },
  documents: {
    agreement: 'https://www.belveb.by/documents/dogovor-o-kompleksnom-bankovskom-obsluzhivanii-fizicheskikh-lits-v-sistemakh-distantsionnogo-bankovskogo-obsluzhivaniya-sdbo/',
    cards: 'https://www.belveb.by/upload/iblock/8bf/8bfbdde20e495769439f8322cc8c6181.pdf',
    rates: 'https://www.belveb.by/documents/tarify-na-vypolnenie-operatsiy-i-okazanie-uslug-oao-bank-belveb/',
    sms: 'https://www.belveb.by/documents/tarify-na-vypolnenie-operatsiy-i-okazanie-uslug-oao-bank-belveb/#page=76&zoom=100,72,788'
  },
  errors: {
    APP_ALREADY_INIT_ERROR: i18n.t('common.errors.appInit'),
    NETWORK_ERROR: i18n.t('common.errors.networkMissing'),
    NETWORK_SETTING_ERROR: i18n.t('common.errors.somethingWrong'),
    TOKEN_MISSING: i18n.t('common.errors.tokenMissing')
  },
  info: {},
  mode: {
    DEV: EnvironmentMode.Dev,
    PROD: EnvironmentMode.Prod
  },
  "package": {
    OPEN: 'openPackage',
    UPGRADE: 'upgradePackage'
  },
  pageSizeHistory: {
    main: 15,
    secondary: 10
  },
  params: {
    PREV_PAGE: 'prevPage'
  },
  server: {
    DEV: Environment.Dev,
    PREPROD: Environment.Preprod,
    PROD: Environment.Prod,
    TEST: Environment.Test
  },
  uri: {
    yandex: 'https://yandex.ru/support2/common/ru/browsers-settings/geolocation.html'
  },
  warnings: {}
};
export { ConstantsUtil };