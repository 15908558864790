export var Drawers;

(function (Drawers) {
  Drawers["AccountsList"] = "AccountsList";
  Drawers["AccountsListWithButton"] = "AccountsListWithButton";
  Drawers["AccountsListWithCreateAccount"] = "AccountsListWithCreateAccount";
  Drawers["BankFilter"] = "BankFilter";
  Drawers["BasicPackage"] = "BasicPackage";
  Drawers["ButtonsList"] = "ButtonsList";
  Drawers["CardsListDrawer"] = "CardsListDrawer";
  Drawers["CheckoutProduct"] = "CheckoutProduct";
  Drawers["CreateCardDrawer"] = "CreateCardDrawer";
  Drawers["CreateFavoriteDrawer"] = "CreateFavoriteDrawer";
  Drawers["CreateFavoriteFolderDrawer"] = "CreateFavoriteFolderDrawer";
  Drawers["CreditInfo"] = "CreditInfoDrawer";
  Drawers["Info"] = "Info";
  Drawers["List"] = "List";
  Drawers["ProductDetails"] = "ProductDetails";
  Drawers["Renaming"] = "Renaming";
  Drawers["ReplenishAccount"] = "ReplenishAccount";
  Drawers["SMSNotification"] = "SMSNotification";
  Drawers["Secure3DS"] = "Secure3DS";
  Drawers["WithHTMLComponent"] = "WithHTMLComponent";
})(Drawers || (Drawers = {}));