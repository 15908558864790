import { styled as o, ButtonGroup as r, buttonGroupClasses as a, buttonClasses as t } from "@mui/material";
var e = o(r)(function (o) {
  var r,
      e,
      n = o.theme,
      p = o.$spacing,
      c = void 0 === p ? 0 : p,
      d = o.$tabVariant,
      i = {
    border: "none",
    borderRadius: "secondary" === d ? n.typography.pxToRem(8) : n.typography.pxToRem(12)
  };
  return (r = {
    backgroundColor: "dark" === d ? n.colors.ghostWhite : null === (e = null == n ? void 0 : n.colors) || void 0 === e ? void 0 : e.white,
    borderRadius: n.typography.pxToRem(16),
    padding: n.spacing(.5)
  })[".".concat(a.grouped, " + .").concat(a.grouped)] = {
    marginLeft: n.spacing(Number(c))
  }, r[".".concat(a.grouped, ":not(:last-of-type)")] = i, r[".".concat(a.grouped, ":not(:first-of-type)")] = i, r[".".concat(a.grouped, ".").concat(t.text, ":hover")] = {
    color: n.colors.abandonedSpaceship
  }, r;
});
export { e as StyledButtonGroup };