import { __read as r, __assign as e } from "../../_virtual/_tslib.js";
import { jsx as t } from "react/jsx-runtime";
import { useState as n, useRef as o, useCallback as i, useEffect as c } from "react";
import { Box as m } from "@mui/material";
import { StyledBox as l } from "./scroll-page-with-gradient.styles.js";

var s = function (s) {
  var u = s.children,
      a = r(n(!1), 2),
      d = a[0],
      f = a[1],
      v = o(),
      w = i(function () {
    var r = document.documentElement,
        e = r.scrollTop,
        t = r.scrollHeight,
        n = r.clientHeight;
    f(e + n >= t - 5);
  }, []);
  return c(function () {
    return window.addEventListener("scroll", w), function () {
      window.removeEventListener("scroll", w);
    };
  }, [w]), c(function () {
    var r = function () {
      clearTimeout(v.current), v.current = setTimeout(w, 100);
    };

    return window.addEventListener("resize", r), function () {
      window.removeEventListener("resize", r);
    };
  }, []), t(l, e({
    $showBottomShadow: !d
  }, {
    children: t(m, {
      children: u
    })
  }));
};

export { s as ScrollPageWithGradient };