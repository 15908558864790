export var getPayslipsExistApiMock = function getPayslipsExistApiMock() {
  return {
    isAnyExists: true
  };
};
export var getPayslipsListApiMock = function getPayslipsListApiMock() {
  return [{
    companyName: 'ОАО "Рога и копыта"',
    id: 1,
    month: 9,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 2,
    month: 10,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 33,
    month: 11,
    year: 2024
  }, {
    companyName: 'ОАО "Рога и копыта"',
    id: 4,
    month: 12,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 6,
    month: 1,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 7,
    month: 2,
    year: 2024
  }, {
    companyName: 'ОАО "Рога и копыта"',
    id: 8,
    month: 3,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 9,
    month: 4,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 10,
    month: 5,
    year: 2024
  }, {
    companyName: 'ОАО "Рога и копыта"',
    id: 11,
    month: 6,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 12,
    month: 7,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 13,
    month: 8,
    year: 2024
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 5,
    month: 9,
    year: 2023
  }, {
    companyName: 'ОАО "Зеленоглазое такси"',
    id: 5,
    month: 3,
    year: 2021
  }];
};