import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledGradient = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
    bottom: 0,
    height: pxToRem(100),
    position: 'fixed',
    width: '100vw'
  };
  return styles;
});
export { StyledGradient };