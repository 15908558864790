import { __rest as l, __read as t, __assign as i } from "../../_virtual/_tslib.js";
import { jsxs as n, jsx as o } from "react/jsx-runtime";
import * as e from "react";
import { Button as a } from "../button/button.component.js";
import { ButtonGroup as r } from "../button-group/button-group.component.js";
import { StyledBox as u } from "./tab.styles.js";
import { TabPanel as m } from "./tab-panel.component.js";
import { getButtonColor as v } from "./tabs.helpers.js";
var d = e.forwardRef(function (d, s) {
  var p,
      c = d.items,
      f = d.fullHeight,
      b = d.onTabChange,
      h = d.initValue,
      g = d.variant,
      j = void 0 === g ? "primary" : g,
      y = l(d, ["items", "fullHeight", "onTabChange", "initValue", "variant"]),
      V = t(e.useState(h || (null === (p = null == c ? void 0 : c[0]) || void 0 === p ? void 0 : p.value)), 2),
      H = V[0],
      x = V[1],
      C = "secondary" === j ? "mediumSmall" : "medium";
  return n(u, i({
    $fullHeight: f
  }, {
    children: [o(r, i({
      ref: s
    }, y, {
      $tabVariant: j,
      fullWidth: "secondary" !== j,
      spacing: "secondary" === j ? 1 : 0
    }, {
      children: null == c ? void 0 : c.map(function (l) {
        return o(a, i({
          color: v(j, H, null == l ? void 0 : l.value),
          disableElevation: !0,
          size: C,
          variant: H === (null == l ? void 0 : l.value) || "primary" !== j ? "contained" : "text",
          onClick: function () {
            null == b || b(null == l ? void 0 : l.value), x(null == l ? void 0 : l.value);
          }
        }, {
          children: null == l ? void 0 : l.title
        }), null == l ? void 0 : l.value);
      })
    })), null == c ? void 0 : c.map(function (l) {
      return o(m, i({
        fullHeight: f,
        tabValue: null == l ? void 0 : l.value,
        tabsValue: H
      }, {
        children: null == l ? void 0 : l.content
      }), null == l ? void 0 : l.value);
    })]
  }));
});
d.displayName = "Tabs";
export { d as Tabs };