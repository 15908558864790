import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/**
 * Reduce payslips function.
 *
 * @author Christina Kashevar
 * @function getPayslipsReduced
 * @category helpers
 * @return {PayslipReduced} array of bottom navigation actions.
 */
export var getPayslipsReduced = function getPayslipsReduced(payslips) {
  var payslipsReduced = payslips.reduce(function (acc, item) {
    var year = item.year.toString();
    acc[year] ? acc[year] = [].concat(_toConsumableArray(acc[year]), [item]) : acc[year] = [item];
    return acc;
  }, {});
  return payslipsReduced;
};
/**
 * Get month function.
 *
 * @author Christina Kashevar
 * @function getMonth
 * @category helpers
 * @return {PayslipReduced} month for i18n.
 */

export var getMonth = function getMonth(month) {
  var monthArr = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  return monthArr[month - 1];
};