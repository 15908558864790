import * as yup from 'yup';
import { i18n } from '@localization';

var getOpenBaseAccountsSchema = function getOpenBaseAccountsSchema() {
  return yup.object().shape({
    accountName: yup.string().trim().required(i18n.t('forms.validation.required')),
    checkContract: yup.bool().equals([true]),
    checkPersonalData: yup.bool().equals([true])
  });
};

export { getOpenBaseAccountsSchema };