var e = {
  abandonedSpaceship: "#767a89",
  alpineGoat: "#f0f0f6",
  amaranth: "#eb424c",
  aragonGreen: "#46be87",
  bayFog: "#9799b1",
  black: "#000000",
  blueBobbin: "#51B2C7",
  brightGray: "#313748",
  carteBlanche: "#e9ecff",
  cloudy: "#ABA5A3",
  clownNose: "#eb4254",
  coolGrey: "#8E93AE",
  deepAquamarine: "#76D7E4",
  deepCove: "#283E51",
  denim: "#1942ae",
  estateViolet: "#c3c2d3",
  etruscanRed: "#c52b3b",
  ghostWhite: "#f7f8fc",
  harvestGold: "#E1B97A",
  hawaiianPassion: "#ff9a3e",
  independence: "#4b516c",
  indigo: "#4754c5",
  laser: "#C9A771",
  lavenderSyrup: "#e7e7f0",
  mensNight: "#5C6072",
  mischka: "#e1e1e9",
  nightWizard: "#333740",
  oxfordBlue: "#3C445A",
  pacificBlue: "#00A0C7",
  panPurple: "#6177f3",
  purpleIllusion: "#afbaf9",
  regentGray: "#919A9E",
  ricePudding: "#fff0e2",
  rollingStone: "#7A8185",
  santasGrey: "#A2A6B8",
  schooner: "#8C8785",
  seaBuckthorn: "#fda92a",
  shark: "#1D2027",
  smokySapphire: "#3B5E7B",
  translucentUnicorn: "#ffedef",
  whisper: "#f5f5fa",
  white: "#FFFFFF",
  whiteSolid: "#f5f5f9"
};
export { e as colors };