/**
 * Mock for current user
 *
 * @author Mikhail Siutsou
 * @function getCurrentUser
 * @category Mock
 * @return {User} current user.
 */
var getClientProfileUserMock = function getClientProfileUserMock() {
  return {
    UNP: '',
    apartmentNumber: '3333333',
    birthPlace: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    birthday: '1998-01-01',
    building: '222',
    cfoCode: 'ВД',
    cfoName: 'тдел розничного бизнеса           1',
    clientAddress: '',
    clientAddress2: '',
    clientLocation: 'АВ',
    clientMnemonic: 'МИТЮШН',
    clientType: 'F',
    district: '',
    docAuthority: 'МММMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM',
    docExpiredDate: '2025-05-31',
    docIssuedDate: '1999-01-01',
    docNumber: '9999999',
    docSeries: 'MP',
    docType: 'ПACПOPT ГPAЖДAHИHA PБ',
    docTypeCode: '03',
    fullName: 'ПОЛНОЕ НАИМЕНОВАНИЕ КЛИЕНТА',
    gender: 'М',
    homeNumber: '7578249',
    homeNumberCode: '8029',
    houseNumber: '111111',
    id: 0,
    localityName: 'МИНСК',
    localityType: 'г.',
    loyaltyProgram: false,
    mobileNumber: '7578249',
    mobileNumberAdd: '',
    mobileNumberAddCode: '',
    mobileNumberCode: '8029',
    nameLatin: 'Ivan',
    nationalityCountry: 'Belarus',
    nationalityCountryCode: 'BY',
    personalNumber: '3070574A200PB2',
    personalNumberMasked: '30705******PB2',
    postCode: '',
    region: '',
    residenceCountry: 'Belarus',
    residenceCountryCode: 'BY',
    segmentCode: '',
    servicePackageCategory: '',
    servicePackageDate: null,
    servicePackageName: '',
    servicePackageType: '',
    soato: '',
    streetName: 'УУУУУ',
    streetType: 'ул.',
    surnameLatin: 'Ivanov',
    uniqueClientNumber: '430065',
    userId: 25,
    userName: 'TEST15'
  };
};

var getClientProfileUserAddInfoMock = function getClientProfileUserAddInfoMock() {
  return {
    hasAccounts: true,
    hasBasicAccount: false,
    hasCurrentAccounts: false,
    hasPackage: true,
    isAllowedNewPackage: true
  };
};

var getClientPackageLevelMock = function getClientPackageLevelMock() {
  return {
    isMaxPackage: false,
    isMinPackage: false
  };
};

export { getClientPackageLevelMock, getClientProfileUserAddInfoMock, getClientProfileUserMock };