import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Button, Stack, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledMoreWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(3)
  });

  return styles;
});
var StyledTabsContent = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    marginTop: theme.spacing(3)
  };
  return styles;
});
var StyledArrowWrapper = styled(Button)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    minWidth: 'unset',
    padding: '0'
  };
  return styles;
});
var StyledStack = styled(Stack)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(12)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    '&  a > span:first-child > span:last-child': {
      marginRight: theme.spacing(1)
    },
    marginTop: theme.spacing(0.25)
  };
  return styles;
});
var StyledLine = styled(Box)(function (_ref6) {
  var _theme$colors;

  var theme = _ref6.theme;
  var styles = {
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    height: pxToRem(2),
    margin: theme.spacing(0, 2)
  };
  return styles;
});
export { StyledArrowWrapper, StyledBox, StyledLine, StyledMoreWrapper, StyledStack, StyledTabsContent };