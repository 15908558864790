import { styled as t, Box as o, alpha as e } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import { colors as i } from "../../themes/colors/colors.js";
import "../../themes/dark.theme.js";
import { pxToRem as a } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var r = t(o)(function (t) {
  var o,
      r = t.theme,
      n = t.$showTopShadow,
      h = t.$showBottomShadow,
      s = t.$showBottomGradient;
  return (o = {})["&:before, &:after"] = {
    borderRadius: "10px",
    content: "''",
    height: 1,
    left: "50%",
    opacity: 0,
    pointerEvents: "none",
    position: "absolute",
    transform: "translate3d(-50%,0,0)",
    transition: r.transitions.create(["opacity"]),
    width: "calc(100% - 20px)",
    zIndex: 1
  }, o["&:after"] = {
    backgroundImage: s ? "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))" : "unset",
    bottom: -1,
    boxShadow: s ? "none" : "0 ".concat(a(-5), " ").concat(a(20), " ").concat(e(i.nightWizard, 1)),
    height: s ? 100 : 1,
    opacity: h ? 1 : void 0,
    width: s ? "100%" : "calc(100% - 20px)"
  }, o["&:before"] = {
    boxShadow: "0 ".concat(a(5), " ").concat(a(20), " ").concat(e(i.nightWizard, 1)),
    opacity: n ? 1 : void 0,
    top: -1
  }, o.display = "flex", o.flex = 1, o.flexDirection = "column", o.maxHeight = "100%", o.overflow = "hidden", o.position = "relative", o.width = "100%", o;
}),
    n = t(o)(function () {
  return {
    height: "100%",
    overflow: "hidden",
    overflowY: "auto",
    position: "relative",
    width: "100%"
  };
});
export { r as ScrollContainer, n as StyledContentWrapper };