import { __rest as o, __read as t, __assign as r } from "../../_virtual/_tslib.js";
import { jsx as e } from "react/jsx-runtime";
import { useState as n, useRef as i, useCallback as l, useEffect as c } from "react";
import { ScrollContainer as s, StyledContentWrapper as m } from "./scroll-shadow.styles.js";

var a = function (a) {
  var d = a.disableTopShadow,
      u = a.disableBottomShadow,
      h = a.children,
      p = a.scrollContent,
      w = a.showBottomGradient,
      f = void 0 !== w && w,
      b = o(a, ["disableTopShadow", "disableBottomShadow", "children", "scrollContent", "showBottomGradient"]),
      v = t(n({
    bottom: !1,
    top: !1
  }), 2),
      S = v[0],
      T = v[1],
      g = i(null),
      B = i(),
      H = l(function () {
    if (g.current) {
      var o = g.current,
          t = o.scrollTop,
          r = 0 === t,
          e = o.scrollHeight - t === o.clientHeight;
      S.top === !r && S.bottom === !e || T({
        bottom: !e,
        top: !r
      });
    }
  }, [S.bottom, S.top]),
      j = function () {
    if (g.current) {
      var o = g.current,
          t = o.scrollHeight,
          r = o.clientHeight,
          e = o.scrollTop,
          n = 0 === e,
          i = t - e === r;
      S.top === !n && S.bottom === !i || T({
        bottom: !i,
        top: !n
      });
    }
  };

  return c(function () {
    if (g.current) {
      var o = g.current,
          t = o.scrollHeight,
          r = o.clientHeight;
      T(t > r ? {
        bottom: !0,
        top: !1
      } : {
        bottom: !1,
        top: !1
      });
    }
  }, [h]), c(function () {
    var o = function () {
      clearTimeout(B.current), B.current = setTimeout(j, 100);
    };

    return window.addEventListener("resize", o), function () {
      window.removeEventListener("resize", o);
    };
  }, []), e(s, r({
    $showBottomGradient: f,
    $showBottomShadow: !u && (null == S ? void 0 : S.bottom),
    $showTopShadow: !d && (null == S ? void 0 : S.top)
  }, b, {
    children: e(m, r({
      ref: g,
      onScroll: H
    }, p, {
      children: h
    }))
  }));
};

export { a as ScrollShadow };