export var BankAffiliationCode;

(function (BankAffiliationCode) {
  BankAffiliationCode["BelVeb"] = "0226";
})(BankAffiliationCode || (BankAffiliationCode = {}));

export var BankServices;

(function (BankServices) {
  BankServices["Balance"] = "Balance";
  BankServices["BillPayments"] = "BillPayments";
  BankServices["CashIn"] = "CashIn";
  BankServices["CashWithdraw"] = "CashWithdraw";
  BankServices["CurrencyExchange"] = "CurrencyExchange";
  BankServices["ForeignReplenishment"] = "ForeignReplenishment";
  BankServices["LoanRepayment"] = "LoanRepayment";
  BankServices["MiniStatement"] = "MiniStatement";
  BankServices["MobileBankingRegistration"] = "MobileBankingRegistration";
  BankServices["Other"] = "Other";
  BankServices["PINActivation"] = "PINActivation";
  BankServices["PINChange"] = "PINChange";
  BankServices["PINUnblock"] = "PINUnblock";
  BankServices["Transfer"] = "Transfer";
})(BankServices || (BankServices = {}));

export var BankDepartmentServices;

(function (BankDepartmentServices) {
  BankDepartmentServices["Card"] = "Card";
  BankDepartmentServices["CurrencyExchange"] = "CurrencyExchange";
  BankDepartmentServices["Deposit"] = "Deposit";
  BankDepartmentServices["DepositBox"] = "DepositBox";
  BankDepartmentServices["DirectDebit"] = "DirectDebit";
  BankDepartmentServices["Loan"] = "Loan";
  BankDepartmentServices["Transfer"] = "Transfer";
})(BankDepartmentServices || (BankDepartmentServices = {}));

export var BankEntities;

(function (BankEntities) {
  BankEntities["Atm"] = "Atm";
  BankEntities["Department"] = "Department";
})(BankEntities || (BankEntities = {}));

export var BankZoom;

(function (BankZoom) {
  BankZoom[BankZoom["Mobile"] = 5.5] = "Mobile";
  BankZoom[BankZoom["Tablet"] = 7] = "Tablet";
  BankZoom[BankZoom["Desktop"] = 7.4] = "Desktop";
  BankZoom[BankZoom["ZoomToPoint"] = 16] = "ZoomToPoint";
  BankZoom[BankZoom["KnownLocation"] = 15] = "KnownLocation";
})(BankZoom || (BankZoom = {}));

export var BankMapCentralPoint = {
  Desktop: [53.540751, 27.320187],
  Mobile: [53.902246, 27.561886]
};
export var BankApiKey = '18caf7f3-e8dc-45c3-9f4a-1b748795d8cb'; // key from belveb.by

export var MaxZoom = 19;
export var MinZoom = 4;
export var ClusterOptions = {
  clusterIconLayout: 'default#pieChart',
  clusterIconPieChartCoreRadius: 18,
  clusterIconPieChartRadius: 24,
  clusterIconPieChartStrokeWidth: 0
};
export var MapOptions = {
  maxZoom: MaxZoom,
  minZoom: MinZoom
};
export var BankIcon = {
  iconOffset: [-21, -45],
  iconOffsetClicked: [-30, -72],
  iconSize: [42, 55],
  iconSizeClicked: [63, 82]
};