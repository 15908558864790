import { Button, pxToRem } from '@pwa/ui';
import { Stack, styled } from '@mui/material';
var StyledButton = styled(Button)(function () {
  var styles = {
    padding: 0,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    '&:last-child div div:last-child': {
      borderBottom: 'none'
    }
  };
  return styles;
});
var StyledStack = styled(Stack)(function (_ref) {
  var _theme$colors;

  var theme = _ref.theme;
  var styles = {
    alignItems: 'start',
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  };
  return styles;
});
export { StyledButton, StyledStack };